import {
  IconBuilding,
  IconShoppingCart,
  IconWallet,
} from "@tabler/icons-react";
import React from "react";
import Logo from "../../images/Logo";

function Option() {
  const pointdata = [
    {
      count: <IconWallet />,
      title: "Payment Wallet",
      content:
        "Our seamless payment system is designed for global eCommerce and merchants. Eliminate fraud, chargebacks, and transaction fees, ensuring secure and efficient transactions worldwide.",
    },
    {
      count: <IconShoppingCart />,
      title: "AI-Driven eCommerce Solutions",
      content:
        "Leverage AI to expand your business offerings across multiple channels. Our AI-driven solutions help businesses optimize operations, enhance customer experiences, and drive growth in the competitive eCommerce landscape. ",
    },
    {
      count: <IconBuilding />,
      title: "Virtual Realtor AI",
      content:
        "Transform the real estate industry with our AI-driven platform for agents and home buyers. Accelerate the home buying process with intelligent tools for property management, market analysis, and client engagement.",
    },
    {
      count: <Logo />,
      title: "Support IQ AI ",
      content:
        "Boost customer engagement with our AI-driven omnichannel unified messaging platform. Increase interaction across social media and other channels with automated AI chatbots, ensuring seamless and responsive customer support. ",
    },
  ];
  return (
    <div className="bg-bgdarkblue px-4">
      <div className=" md:py-[120px] py-[80px] max-w-[1408px]  mx-auto ">
        <h2 className="md:text-[44px] text-[32px] text-blue-300 font-UncutSans lg:min-w-[500px] text-center mx-auto mb-12">
          Our Business Accelerator Platforms Developed{" "}
          <br className="lg:block hidden" /> to help businesses operate with
          efficiency
        </h2>
        <div className="gap-[64px] mx-auto grid md:grid-cols-2 grid-cols-1 max-w-[864px]">
          {pointdata.map((item, index) => (
            <div className="overflow-hidden  col-span-1" key={index}>
              <div className="size-12 bg-blue-300 rounded-full mb-[25px] text-bgdarkblue text-2xl font-UncutSans flex items-center justify-center">
                {item.count}
              </div>
              <h4 className="text-2xl text-gray-200 font-UncutSans mb-2">
                {item.title}
              </h4>
              <p className="text-gray-300 font-extralight">{item.content}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Option;
