import React from "react";

function Threecols({ pointdata }) {
  return (
    <div className="pt-[64px] gap-[64px]  mx-auto grid lg:grid-cols-3 grid-cols-1">
      {pointdata.map((item, index) => (
        <div className="overflow-hidden  col-span-1" key={index}>
          <div className="size-12 bg-themecolor rounded-full mb-[25px] text-white text-2xl font-UncutSans flex items-center justify-center">
            {item.count}
          </div>
          <h4 className="text-2xl text-gray-800 text-6 font-UncutSans mb-2">
            {item.title}
          </h4>
          <p className="text-gray-700 text-justify">{item.content}</p>
        </div>
      ))}
    </div>
  );
}

export default Threecols;
