import React from "react";
import Hero from "./Hero";
import Button from "../../components/Button";
import Point from "../../components/Point";
import OurPlatform from "./OurPlatform";
// import OmnichannelSolution from "./OmnichannelSolution";
import Option from "./Option";
// import CEO from "./CEO";

function Home() {
  return (
    <>
      <div data-aos="fade-up">
        <Hero />
        <div className="bg-bgdarkblue px-4">
          <div className=" md:py-[120px] py-[80px] max-w-[1408px] flex lg:gap-[64px] md:gap-[32px] gap-6 mx-auto md:flex-nowrap flex-wrap">
            <h2 className="md:text-[44px] text-[32px] text-blue-300 font-UncutSans lg:min-w-[500px] md:w-1/2 w-full">
              Comprehensive AI-Driven Solutions for Real Estate, Customer
              Engagement, and Data Security
            </h2>
            <div className="lg:w-auto md:w-1/2 w-full">
              <p className="text-lg text-gray-200 mb-6">
                At Millenniuxt, we revolutionize business operations with
                AI-driven solutions. Our AI-Driven Real Estate Platform enhances
                property management and market analysis for realtors. Support IQ
                AI boosts customer engagement with advanced AI chatbots,
                ensuring seamless interactions. Our Credit App safeguards
                personal information with proprietary AI algorithms, providing
                robust data security. These solutions enhance productivity,
                reshape industries, and drive unprecedented growth, delivering
                exceptional value to customers.
              </p>
              <Button btnText="Join Us" btnbg="white" />
            </div>
          </div>
        </div>
        <Point />
        <OurPlatform />
        {/* <OmnichannelSolution /> */}
        <Option />
        {/* <CEO /> */}
      </div>
    </>
  );
}

export default Home;
