import React from "react";
import CenterTitle from "../../components/CenterTitle";
import supportiqwhitelogo from "../../images/supportiqwhitelogo.png";
import Button from "../../components/Button";

function OurPlatform() {
  return (
    <div className="py-[60px] max-w-[1344px] px-4 mx-auto">
      <CenterTitle
        title="Our Platforms"
        subtext="Explore Our AI-Driven Solutions for Real Estate, Customer Engagement, and Data Security "
      />
      <div className="mt-[80px]">
        <div className="max-w-[1408px] flex lg:gap-[64px] md:gap-[32px] gap-6 mx-auto md:flex-nowrap flex-wrap items-center">
          <div className="overflow-hidden h-[344px] lg:min-w-[538px] md:w-[50%] w-full rounded-md bg-image-h1 col-span-1 flex items-center justify-center">
            <div className="flex items-center gap-2">
              <img src={supportiqwhitelogo} alt={supportiqwhitelogo} />
              <h3 className="text-white text-[34px] font-semibold">
                SupportIQ
              </h3>
            </div>
          </div>
          <div className="lg:w-auto md:w-1/2 w-full">
            <h3 className="text-[32px] font-UncutSans mb-4">
              AI-Driven Customer Engagement Platform
            </h3>
            <p className="mb-6 text-gray-700">
              Support IQ AI offers businesses an AI-driven approach to customer
              engagement through Automated AI Chatbots. Our AI Customer
              Engagement solutions ensure seamless interactions and increased
              customer satisfaction. With AI Chatbot Solutions, companies can
              foster stronger relationships through personalized and responsive
              service, providing consistent support around the clock. Experience
              Intelligent Customer Support that adapts to your clients' needs,
              enhancing their overall experience with AI-Driven Customer
              Service.
            </p>
            <Button btnText="Export" />
          </div>
        </div>
      </div>
      <div className="mt-[80px]">
        <div className="max-w-[1408px] flex lg:gap-[64px] md:gap-[32px] gap-6 mx-auto md:flex-nowrap flex-wrap items-center">
          <div className="lg:w-auto md:w-1/2 w-full md:order-[0] order-1 ">
            <h3 className="text-[32px] font-UncutSans mb-4">
              Revolutionize Real Estate with AI
            </h3>
            <p className="mb-6 text-gray-700">
              Our AI Real Estate Solutions leverage advanced AI technology to
              provide realtors with intelligent solutions for property
              management, market analysis, and customer engagement. With AI for
              Realtors, you can make smarter decisions, optimize property
              listings, and enhance client satisfaction. Our AI-Driven Property
              Management platform offers unparalleled insights and efficiency,
              ensuring that every aspect of property management is streamlined
              and effective. Experience the future of real estate with our Real
              Estate AI Technology and Machine Learning for Real Estate
              capabilities.
            </p>
            <Button btnText="Export" />
          </div>
          <div className="overflow-hidden h-[344px] lg:min-w-[538px] md:w-[50%] w-full md:order-1 order-[0] rounded-md bg-image-h2 col-span-1 flex items-center justify-center">
            <h3 className="text-white text-[34px] font-semibold">VRealtor</h3>
          </div>
        </div>
      </div>
      <div className="mt-[80px]">
        <div className="max-w-[1408px] flex lg:gap-[64px] md:gap-[32px] gap-6 mx-auto md:flex-nowrap flex-wrap items-center">
          <div className="overflow-hidden h-[344px] lg:min-w-[538px] md:w-[50%] w-full rounded-md bg-image-h3 col-span-1 flex items-center justify-center">
            <h3 className="text-white text-[34px] font-semibold">
              FortKnoxify
            </h3>
          </div>
          <div className="lg:w-auto md:w-1/2 w-full">
            <h3 className="text-[32px] font-UncutSans mb-4">
              Advanced AI Credit Protection and Data Security Solutions
            </h3>
            <p className="mb-6 text-gray-700">
              Our Credit App uses a proprietary algorithm to safeguard personal
              information, ensuring robust AI Credit Protection. With AI-Driven
              Credit Security, businesses can protect financial data and
              consumer privacy. Leveraging Consumer Data Protection AI, we
              guarantee that personal information remains secure and private.
              Trust our advanced AI Personal Information Security to maintain
              the highest standards of privacy and protection.
            </p>
            <Button btnText="Export" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default OurPlatform;
