import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../images/logo.png";
import { IconMenu2, IconX, IconChevronDown } from "@tabler/icons-react";

function Header() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [dropmenushow, setDropmenu] = useState(false);
  const location = useLocation();

  if (menuOpen) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "auto";
  }

  const dropmenu = () => {
    setDropmenu(!dropmenushow);
  };
  return (
    <div className="px-4">
      <div className="max-w-[1312px] py-8 flex justify-between items-center mx-auto">
        <div>
          <Link to="/">
            <img src={logo} alt="Logo" />
          </Link>
        </div>
        <ul className="hidden md:flex justify-between items-center gap-6">
          <li className="py-3">
            <Link
              to="/"
              className="text-gray-700 text-sm font-medium hover:text-themecolor flex"
            >
              Home
            </Link>
          </li>
          <li className="py-3">
            <Link
              to="/about"
              className="text-gray-700 text-sm font-medium hover:text-themecolor flex"
            >
              About
            </Link>
          </li>
          <li className="relative group py-3">
            <Link className="text-gray-700 text-sm font-medium hover:text-themecolor flex items-center gap-1">
              Platforms
              <IconChevronDown size={20} />
            </Link>
            <ul className="absolute  top-[50px] grid bg-white shadow-lg w-[160px] p-4 rounded-md border border-gray-200 opacity-0 transition-all invisible group-hover:opacity-100 group-hover:top-[40px] group-hover:visible z-[100]">
              <li>
                <Link
                  to="/vrealtor"
                  className="text-gray-700 text-sm font-medium hover:text-themecolor flex py-2"
                >
                  VRealtor
                </Link>
              </li>
              <li>
                <Link
                  to="/supportiq"
                  className="text-gray-700 text-sm font-medium hover:text-themecolor flex py-2"
                >
                  Supportiq AI
                </Link>
              </li>
              <li>
                <Link
                  to="/fortknoxify"
                  className="text-gray-700 text-sm font-medium hover:text-themecolor flex py-2"
                >
                  Fortknoxify
                </Link>
              </li>
            </ul>
          </li>
          <li className="py-3">
            <Link
              to="/news"
              className="text-gray-700 text-sm font-medium hover:text-themecolor flex"
            >
              News
            </Link>
          </li>
          <li className="py-3">
            <Link
              to="/contact-us"
              className="text-gray-700 text-sm font-medium hover:text-themecolor flex"
            >
              Contact Us
            </Link>
          </li>
        </ul>
        <div className="md:hidden flex items-center">
          <button onClick={() => setMenuOpen(true)}>
            <IconMenu2 className="text-gray-700 w-8 h-8" />
          </button>
        </div>
      </div>

      <div
        className={`md:hidden bg-white fixed top-0 transition-all duration-300 ${
          menuOpen ? "right-[0%]" : "-right-[80%]"
        } w-[80%] h-screen z-[999]`}
      >
        <div className="w-full">
          <IconX
            onClick={() => {
              setMenuOpen(false);
              setDropmenu(false);
            }}
            className="text-gray-700 w-8 h-8 ml-auto mr-4 mt-10 cursor-pointer"
          />
        </div>
        <ul className="grid gap-6  pl-7 mt-5">
          <li>
            <Link
              to="/"
              className="text-gray-700 text-sm font-medium hover:text-themecolor"
              onClick={() => {
                setMenuOpen(false);
                setDropmenu(false);
              }}
            >
              Home
            </Link>
          </li>
          <li>
            <Link
              to="/about"
              className="text-gray-700 text-sm font-medium hover:text-themecolor"
              onClick={() => {
                setMenuOpen(false);
                setDropmenu(false);
              }}
            >
              About
            </Link>
          </li>
          <li>
            <Link
              className="text-gray-700 text-sm font-medium hover:text-themecolor flex items-center gap-1"
              onClick={dropmenu}
            >
              Platform
              <IconChevronDown
                size={20}
                className={`transition-all ${dropmenushow ? "rotate-180" : ""}`}
              />
            </Link>
            <div
              className={` overflow-hidden transition-all ${
                dropmenushow ? "h-[132px]" : "h-0"
              }`}
              onClick={() => setDropmenu(false)}
            >
              <ul className="grid gap-6 pt-6 pl-6" id="dropmenuheight">
                <li>
                  <Link
                    to="/vrealtor"
                    className="text-gray-700 text-sm font-medium hover:text-themecolor flex"
                    onClick={() => {
                      setMenuOpen(false);
                      setDropmenu(false);
                    }}
                  >
                    VRealtor
                  </Link>
                </li>
                <li>
                  <Link
                    to="/supportiq"
                    className="text-gray-700 text-sm font-medium hover:text-themecolor flex"
                    onClick={() => {
                      setMenuOpen(false);
                      setDropmenu(false);
                    }}
                  >
                    Supportiq AI
                  </Link>
                </li>
                <li>
                  <Link
                    to="/fortknoxify"
                    className="text-gray-700 text-sm font-medium hover:text-themecolor flex"
                    onClick={() => {
                      setMenuOpen(false);
                      setDropmenu(false);
                    }}
                  >
                    Fortknoxify
                  </Link>
                </li>
              </ul>
            </div>
          </li>
          <li>
            <Link
              to="/news"
              className="text-gray-700 text-sm font-medium hover:text-themecolor"
              onClick={() => {
                setMenuOpen(false);
                setDropmenu(false);
              }}
            >
              News
            </Link>
          </li>
          <li>
            <Link
              to="/contact-us"
              className="text-gray-700 text-sm font-medium hover:text-themecolor"
              onClick={() => {
                setMenuOpen(false);
                setDropmenu(false);
              }}
            >
              Contact Us
            </Link>
          </li>
        </ul>
      </div>
      {menuOpen && (
        <div
          className="w-screen h-screen fixed top-0 left-0 bg-[#00000059]"
          onClick={() => setMenuOpen(false)}
        ></div>
      )}
      {location.pathname === "/" && (
        <div className="size-[218px] bg-themecolor blur-[140px] rounded-full absolute left-1/2 top-0 -translate-x-1/2"></div>
      )}
    </div>
  );
}

export default Header;
