import React from "react";
import supportiqwhitelogo from "../../images/supportiqwhitelogo.png";
import Button from "../../components/Button";

function Hero() {
  return (
    <div className="pt-12">
      <div className="max-w-[850px] px-4 text-center mx-auto">
        <h1 className="font-UncutSans lg:text-6xl lg:leading-[72px] md:text-5xl text-4xl text-gray-800 mb-4">
          Transforming Business Operations with AI-Driven Solutions
        </h1>
        <p className="md:text-lg text-base text-gray-700 mb-6">
          At Millenniuxt, we power Line experiences with next-level AI for
          seamless customer service. Our solutions unlock growth and reshape
          industries. Launch your business into the future
        </p>
        <Button btnText="Join Us" />
      </div>
      <div className="py-[80px] gap-6 max-w-[1344px] px-4 mx-auto grid lg:grid-cols-3 grid-cols-1">
        <div className="overflow-hidden h-[273px] rounded-md bg-image-h1 col-span-1 flex items-center justify-center">
          <div className="flex items-center gap-2">
            <img src={supportiqwhitelogo} alt={supportiqwhitelogo} />
            <h3 className="text-white text-[34px] font-semibold">SupportIQ</h3>
          </div>
        </div>
        <div className="overflow-hidden h-[273px] rounded-md bg-image-h2 col-span-1 flex items-center justify-center">
          <h3 className="text-white text-[34px] font-semibold">VRealtor</h3>
        </div>
        <div className="overflow-hidden h-[273px] rounded-md bg-image-h3 col-span-1 flex items-center justify-center">
          <h3 className="text-white text-[34px] font-semibold">FortKnoxify</h3>
        </div>
      </div>
    </div>
  );
}

export default Hero;
