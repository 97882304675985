import React from "react";
import Titlepage from "../../components/Titlepage";
import supportiq_platform_1 from "../../images/supportiq_platform_1.png";
import supportiq_platform_1chat from "../../images/supportiq_platform_1chat.png";
import supportiq_platform_1dashboard from "../../images/supportiq_platform_1dashboard.png";
import PlatFormImage from "../../components/PlatFormImage";
import PlatformTextWithImage from "../../components/PlatformTextWithImage";

function Supportiq() {
  const imageText = [
    {
      img: supportiq_platform_1chat,
      alt: "supportiq_platform_1",
      text1:
        " Support IQ AI: The Ultimate Omnichannel Unified Messaging and Customer Engagement Platform",
      text2:
        "Support IQ AI is an advanced Omnichannel Unified Messaging and customer engagement platform powered by AI. It is designed to help businesses increase engagement, reach new customers, and handle customer support seamlessly. Our unique AI customer sentiment analysis provides businesses with valuable insights to understand customer satisfaction and the intent to buy products or services, making it an essential tool for enhancing customer experience and driving growth. Additionally, our AI chatbot can provide customer service support across social media platforms 24/7, ensuring continuous and efficient customer interaction",
    },
  ];
  return (
    <div data-aos="fade-up">
      <Titlepage title="Support IQ AI increases customer engagement through automated AI chatbots." />
      <PlatFormImage src={supportiq_platform_1} alt="supportiq_platform_1" />
      <PlatformTextWithImage imageText={imageText} />
      <div className="md:py-[120px] py-[60px] px-4">
        <div className="max-w-[1344px] mx-auto mb-[45px]">
          <div className="grid md:grid-cols-2 grid-cols-1 gap-[64px]">
            <div className="col-span-1 flex flex-col">
              <h3 className="font-UncutSans text-[28px] mb-4">
                Unified Messaging
              </h3>
              <p className="text-gray-700 mb-8">
                Streamline customer communication by integrating various
                channels like WhatsApp, Facebook, Instagram, Telegram, Text
                messaging and more into single unified Inbox
              </p>
              <div className="mt-auto">
                <img
                  src={supportiq_platform_1dashboard}
                  alt="supportiq_platform_1dashboard"
                  className="mx-auto"
                />
              </div>
            </div>
            <div className="col-span-1 flex flex-col">
              <h3 className="font-UncutSans text-[28px] mb-4">
                Maximize Engagement with Targeted Campaigns
              </h3>
              <p className="text-gray-700 mb-8">
                Allow the AI to determine milestones and send relevant messages
                to contacts
              </p>
              <div className="mt-auto">
                <img
                  src={supportiq_platform_1dashboard}
                  alt="supportiq_platform_1dashboard"
                  className="mx-auto"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Supportiq;
