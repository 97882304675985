import React from "react";

function HappyToHear() {
  return (
    <div className="py-[60px] px-4">
      <div className="max-w-[1408px] px-4 mx-auto rounded-[40px] bg-bgdarkblue p-[64px] blue-round-blur relative">
        <div className="max-w-[800px] mx-auto text-center">
          <h3 className="md:text-[60px] text-[38px] text-blue-300 font-UncutSans font-medium mb-10">
            Happy To Hear From You
          </h3>
          <div className="flex items-center gap-6 justify-center">
            <button className="bg-white  text-bgdarkblue w-[250px] py-4 rounded-full">
              <a href="mailto:hello@millenniuxt.com">Mail Us</a>
            </button>
            <button className="bg-white  text-bgdarkblue w-[250px] py-4 rounded-full">
              <a href="tel:+13104000531">Call Us</a>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HappyToHear;
