import React from "react";

function CenterTitle({ title, subtext }) {
  return (
    <div className="mx-auto max-w-[500px] text-center">
      <h3 className="sm:text-[44px] text-4xl text-gray-800 font-UncutSans mb-4">
        {title}
      </h3>
      <p className="text-gray-800">{subtext}</p>
    </div>
  );
}

export default CenterTitle;
