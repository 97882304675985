import React from "react";

function Button({ btnText, btnbg, classnameadd }) {
  return (
    <>
      {btnbg === "white" ? (
        <button
          className={`bg-white  text-bgdarkblue w-[250px] py-4 rounded-full ${classnameadd}`}
        >
          {btnText}
        </button>
      ) : (
        <button
          className={`bg-themecolor hover:bg-hovercolor text-white w-[250px] py-4 rounded-full ${classnameadd}`}
        >
          {btnText}
        </button>
      )}
    </>
  );
}

export default Button;
