import React from "react";
import Titlepage from "../../components/Titlepage";
import VRealtorimg from "../../images/VRealtorimg.png";
import chat from "../../images/chat.png";
import PlatFormImage from "../../components/PlatFormImage";
import PlatformTextWithImage from "../../components/PlatformTextWithImage";

function Platform() {
  const vrealtordata = [
    {
      count: "1",
      title: "AI-Driven Property Search",
    },
    {
      count: "2",
      title: "AI-Driven Property Search",
    },
    {
      count: "3",
      title: "Connect with Agent or DIY",
    },
    {
      count: "4",
      title: "Virtual Viewing",
    },
    {
      count: "5",
      title: "In Person Viewing",
    },
    {
      count: "6",
      title: "Submit Offer",
    },
    {
      count: "7",
      title: "Inspection, Appraisal, Final, Approval",
    },
    {
      count: "8",
      title: "Closing",
    },
  ];
  const imageText = [
    {
      img: chat,
      alt: "chat",
      text1:
        " Revolutionizing Home Buying: AI-Driven Insights for Quick Decisions",
      text2:
        "Virtual Realtor AI empowers real estate agents to operate at peak efficiency while equipping home buyers and sellers with the vital information they need to make swift, informed decisions. This AI-powered platform transforms the property market landscape by offering a comprehensive suite of tools designed to streamline every aspect of real estate transactions. For agents, Virtual Realtor AI provides features such as real-time client interaction through digital twins, fast and secure prequalification credit checks, and the ability to conduct live open houses, significantly reducing time and effort. For direct buyers and sellers, the platform ensures access to detailed property listings, personalized virtual tours, and seamless document reviews, enhancing the overall buying and selling experience. By integrating cutting-edge artificial intelligence, Virtual Realtor AI not only enhances operational efficiency but also increases customer satisfaction and engagement, ultimately driving success in the real estate market",
    },
  ];

  return (
    <div data-aos="fade-up">
      <Titlepage
        title="Revolutionizing Home Buying and Selling with AI-Driven Insights"
        content="Welcome to VRealtor, the cutting-edge AI-driven real estate platform designed to help realtors operate with peak efficiency and empower home buyers and sellers with the information they need to make swift, informed decisions. Our platform transforms the property market landscape by offering a comprehensive suite of tools designed to streamline every aspect of real estate transactions. "
      />
      <PlatFormImage src={VRealtorimg} alt="VRealtorimg" />
      <PlatformTextWithImage imageText={imageText} />
      <div className="md:py-[120px] py-[60px] px-4">
        <div className="max-w-[1344px] mx-auto mb-[45px]">
          <div className="grid md:grid-cols-2 grid-cols-1 gap-[64px]">
            <div className="col-span-1">
              <h3 className="font-UncutSans text-[28px] mb-4">
                Digital Twin Realtors
              </h3>
              <p className="text-gray-700">
                Digital Twin Realtors: AI agents available 24/7, offering expert
                advice tailored to your home buying needs.
              </p>
            </div>
            <div className="col-span-1">
              <h3 className="font-UncutSans text-[28px] mb-4">
                Ai Driven Idenfication & Credit Decision
              </h3>
              <p className="text-gray-700">
                AI-Driven Verification: Secure, private identity and credit
                checks, giving buyers control over their data.
              </p>
            </div>
          </div>
        </div>
        <div className="max-w-[1344px] mx-auto">
          <div className="flex justify-center flex-wrap gap-[64px] ">
            {vrealtordata.map((item, index) => (
              <div
                className="border border-gray-200 rounded-full p-2 flex items-center gap-2 min-w-[345px] w-[30%]"
                key={index}
              >
                <div className="border border-gray-200 rounded-full size-10 p-2 flex justify-center items-center bg-gray-50">
                  {item.count}
                </div>
                {item.title}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Platform;
