import React from "react";

function Titlepage({ title, classnametext, content }) {
  return (
    <div className="py-[80px] gap-6 max-w-[1344px] px-4 mx-auto">
      <h2
        className={`font-UncutSans lg:text-6xl lg:leading-[72px] md:text-5xl text-4xl text-gray-800 mb-4 ${
          classnametext && classnametext
        }`}
      >
        {title}
        {content && (
          <p className="md:text-lg text-base text-gray-700 mb-6 pt-5">
            {content}
          </p>
        )}
      </h2>
    </div>
  );
}

export default Titlepage;
