import React from "react";

function Logo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#001449"
        d="M10.594 15.155L7.17 11.764l3.328-3.265c-.276-.28-.557-.573-.835-.88a5.941 5.941 0 01-1.604-3.72L.786 11.035a1.014 1.014 0 000 1.448L12.033 23.52l.73-.867a4.07 4.07 0 00.996-2.71 4.073 4.073 0 00-1.059-2.687 32.236 32.236 0 00-2.106-2.101zm2.731-6.79l3.424 3.39-3.323 3.262c.276.28.558.573.835.88a5.95 5.95 0 011.598 3.725l7.275-7.139a1.014 1.014 0 000-1.447L11.887 0l-.73.867a4.07 4.07 0 00-.995 2.71 4.073 4.073 0 001.06 2.687 32.774 32.774 0 002.103 2.101z"
      ></path>
    </svg>
  );
}

export default Logo;
