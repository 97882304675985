import "./App.css";
import { useEffect } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import Header from "./components/Header";
import Home from "./Pages/Home";
import Footer from "./components/Footer";
import About from "./Pages/About";
import Platform from "./Pages/Platform";
import Supportiq from "./Pages/Platform/Supportiq";
import Supportiq2 from "./Pages/Platform/Supportiq2";
import News from "./Pages/News";
import HappyToHear from "./components/HappyToHear";
import ContactUs from "./Pages/Contact Us";
import Error404 from "./Pages/Error404";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();

function App() {
  const Scroll = () => {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
      if (window.location.href.includes("#pricing")) {
        setTimeout(() => {
          const el = document.getElementById("pricing");
          el?.scrollIntoView({ behavior: "smooth" });
        }, 300);
      } else {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    }, [pathname]);

    return null;
  };
  return (
    <BrowserRouter>
      <Header />
      <Scroll />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/vrealtor" element={<Platform />} />
        <Route path="/supportiq" element={<Supportiq />} />
        <Route path="/fortknoxify" element={<Supportiq2 />} />
        <Route path="/News" element={<News />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="*" element={<Error404 />} />
      </Routes>
      <HappyToHear />
      <Footer />
    </BrowserRouter>
  );
}

export default App;
