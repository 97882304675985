import React from "react";
import aboutimg1 from "../../images/aboutimg1.jpg";
import Titlepage from "../../components/Titlepage";
import Button from "../../components/Button";
// import Value from "./Value";
import OurTeam from "./OurTeam";

function About() {
  return (
    <div data-aos="fade-up">
      <Titlepage
        title="Leading Provider of AI-Driven Business Solutions"
        classnametext="max-w-[714px]"
        content="Welcome to Millenniuxt - your premier destination for AI-driven solutions that are revolutionizing the future of business operations. At Millenniuxt, we believe in the transformative power of technology to reshape industries, redefine productivity, and revolutionize the way businesses function. Our cutting-edge platform harnesses the unparalleled capabilities of Artificial Intelligence (AI) and Machine Learning (ML) to deliver exceptional results across various sectors."
      />
      <div className="mt-[80px] pb-[120px] px-4">
        <div className="max-w-[1344px] flex lg:gap-[64px] md:gap-[32px] gap-6 mx-auto md:flex-nowrap flex-wrap items-center">
          <img src={aboutimg1} alt="aboutimg1" className="md:w-[50%] w-full " />
          <div className="lg:w-auto md:w-1/2 w-full">
            <h3 className="text-[32px] font-UncutSans mb-4">
              AI Driven Omnichannel Unified Messaging
            </h3>
            <p className="mb-6 text-gray-700 text-lg">
              Welcome to Millenniuxt, where innovation meets efficiency to
              create the future of business operations. At the core of our
              ethos, we believe in the transformative power of technology to
              reshape industries, redefine productivity, and revolutionize the
              way businesses function. Our platform stands at the forefront of
              this transformation, harnessing the unparalleled capabilities of
              Artificial Intelligence (AI) and Machine Learning (ML) to offer
              unparalleled acceleration in business operations.
            </p>
            <Button btnText="Our Platforms" />
          </div>
        </div>
      </div>
      {/* <Value /> */}
      <OurTeam />
    </div>
  );
}

export default About;
