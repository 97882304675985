import React from "react";

function PlatFormImage({ src, alt }) {
  return (
    <div className="mx-auto md:mb-[120px] mb-[60px] px-4">
      <img src={src} alt={alt} className="mx-auto" />
    </div>
  );
}

export default PlatFormImage;
