import React from "react";
import Button from "./Button";

function PlatformTextWithImage({ imageText }) {
  return (
    <div className="md:py-[120px] py-[60px] px-4">
      <div className="max-w-[1344px] mx-auto">
        {imageText?.map((item, index) => {
          return (
            <div
              className="flex lg:gap-[64px] md:gap-[32px] md:flex-nowrap flex-wrap gap-6 items-center"
              key={index}
            >
              <div className="lg:w-[33%] md:w1/2 w-full">
                <img src={item?.img} alt={item?.alt} className="mx-auto" />
              </div>
              <div className="lg:w-[67%] md:w1/2 w-full">
                <h3 className="text-[32px] font-UncutSans mb-4">
                  {item?.text1}
                </h3>
                <p className="mb-6 text-gray-700 text-lg">{item?.text2}</p>
                <Button btnText="Export VRealtor" />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default PlatformTextWithImage;
