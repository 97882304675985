import React from "react";
import Titlepage from "../../components/Titlepage";
import supportiq_platform_2 from "../../images/supportiq_platform_2.png";
import supportiq_platform_2mitter from "../../images/supportiq_platform_2mitter.png";
import supportiq_platform_2blog_1 from "../../images/supportiq_platform_2blog_1.jpg";
import supportiq_platform_2blog_2 from "../../images/supportiq_platform_2blog_2.jpg";
import PlatFormImage from "../../components/PlatFormImage";
import PlatformTextWithImage from "../../components/PlatformTextWithImage";

function Supportiq2() {
  const imageText = [
    {
      img: supportiq_platform_2mitter,
      alt: "supportiq_platform_2mitter",
      text1: " FortKnoxify Credit App: Highest level of Security",
      text2:
        "FortKnofixy Credit App revolutionizes the credit check process for businesses, offering secure, instant verifications that enhance efficiency for credit lenders. The proprietary algorithm ensures consumer information is protected and accessible only through digital authorization, boosting confidence and speeding up decision-making processes.",
    },
  ];
  return (
    <div data-aos="fade-up">
      <Titlepage title="Our Credit App invokes proprietary algorithm designed to protect consumer Personal information." />
      <PlatFormImage src={supportiq_platform_2} alt="supportiq_platform_2" />
      <PlatformTextWithImage imageText={imageText} />
      <div className="md:py-[120px] py-[60px] px-4">
        <div className="max-w-[1344px] mx-auto ">
          <div className="grid md:grid-cols-2 grid-cols-1 gap-[64px]">
            <div className="col-span-1 flex flex-col">
              <h3 className="font-UncutSans text-[28px] mb-4">
                Payment Calculation
              </h3>
              <p className="text-gray-700 ">
                In addition to robust security measures, Fortknoxify leverages
                advanced AI capabilities to enhance the consumer experience.
                Once a credit check is authorized and an amount is approved, the
                AI seamlessly calculates payment terms tailored to the
                consumer's financial profile. This includes interest rates,
                monthly payment amounts, and loan duration, providing a clear
                and comprehensive view of the borrowing terms.
              </p>
            </div>
            <div className="col-span-1 flex flex-col">
              <h3 className="font-UncutSans text-[28px] mb-4">
                Enhanced Business Operations: Secure and Efficient
              </h3>
              <p className="text-gray-700 ">
                Built for lending industry (Mortgage, Auto, Personal Loand,
                Credit card and more), FortKnofixy Credit App streamlines
                business operations by eliminating manual processes. With
                digital signature authorization, lenders can obtain credit
                reports with a single click, removing the need for lengthy
                credit application forms. This efficiency not only saves time
                but also enhances the overall user experience for borrowers and
                lenders alike
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="md:py-[120px] py-[60px] px-4">
        <div className="max-w-[1344px] mx-auto ">
          <div className="grid md:grid-cols-2 grid-cols-1 gap-[64px]">
            <div className="col-span-1 flex flex-col">
              <h3 className="font-UncutSans text-[28px] mb-4">
                Credit Report Template for various Lenders
              </h3>
              <p className="text-gray-700 mb-8">
                The Fortknoxify app includes templates suited for several
                industries such as auto loans, Mortgage lenders, personal loans,
                store credit and credit cards.
              </p>
              <div className="mt-auto">
                <img
                  src={supportiq_platform_2blog_1}
                  alt="supportiq_platform_2blog_1"
                  className="mx-auto"
                />
              </div>
            </div>
            <div className="col-span-1 flex flex-col">
              <h3 className="font-UncutSans text-[28px] mb-4">
                Highest Level of Security
              </h3>
              <p className="text-gray-700 mb-8">
                Consumer PII is encrypted and securely stored on the mobile
                device. Lenders can request a credit check authorization, But
                only the consumer can authorize it using a secured digital
                authorization signature
              </p>
              <div className="mt-auto">
                <img
                  src={supportiq_platform_2blog_2}
                  alt="supportiq_platform_2blog_2"
                  className="mx-auto"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Supportiq2;
