import React from "react";
import logo from "../images/logo.png";
import { Link } from "react-router-dom";
import {
  IconBrandFacebook,
  IconBrandInstagram,
  IconBrandLinkedin,
  IconBrandX,
  IconMail,
  IconMapPin,
  IconPhone,
} from "@tabler/icons-react";

const headermenu = [
  {
    link: "/about",
    title: "About",
  },
  {
    link: "/vrealtor",
    title: "VRealtor",
  },
  {
    link: "/supportiq",
    title: "Supportiq AI",
  },
  {
    link: "/fortknoxify",
    title: "Fortknoxify",
  },
  {
    link: "/news",
    title: "News",
  },
  {
    link: "/contact-us",
    title: "Contact Us",
  },
];

function Footer() {
  return (
    <div className="py-[60px] max-w-[1344px] px-4 mx-auto">
      <div className="flex justify-between flex-wrap gap-y-8 mb-12">
        <div className=" max-w-[360px]">
          <img src={logo} alt="supportiqwhitelogo" className="mb-4" />
          <p className="text-sm text-gray-800">
            We create transformation platform designed to accelerate businesses
            operation with efficieny.
          </p>
        </div>
        <div className="flex gap-[120px] flex-wrap gap-y-8">
          <div>
            <p className="text-sm text-gray-600 mb-5">Company</p>
            <ul className="">
              {headermenu.map((item, index) => (
                <li key={index} className="mb-3">
                  <Link
                    to={item.link}
                    className="text-gray-700 text-sm font-medium hover:text-themecolor "
                  >
                    {item.title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div>
            <p className="text-sm text-gray-600 mb-5">Contact</p>
            <div className="text-gray-800 flex items-center gap-3 mb-3">
              <IconPhone size={20} />
              <a href="tel:+13104000531">+1 (310) 400-0531</a>
            </div>
            <div className="text-gray-800 flex items-center gap-3 mb-3">
              <IconMail size={20} />
              <a href="mailto:hello@millenniuxt.com">hello@millenniuxt.com</a>
            </div>
            <div className="text-gray-800 flex items-center gap-3 mb-3">
              <IconMapPin size={20} />
              <p>8 The Green Ste 300, Dover DE 19901</p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center md:justify-between md:flex-row flex-col-reverse justify-center pt-12 border-t border-gray-100 gap-4 gap-y-6">
        <p className="text-gray-600 text-sm ">
          © Copyrights 2024. Millenniuxt Inc. All rights reserved.
        </p>
        <div className="flex items-center  justify-between gap-4">
          <Link
            target="blank"
            to="/"
            className="rounded-full border border-gray-100 p-3 shadow-sm"
          >
            <IconBrandFacebook />
          </Link>
          <Link
            target="blank"
            to="https://www.linkedin.com/company/millenniuxt-inc"
            className="rounded-full border border-gray-100 p-3 shadow-sm"
          >
            <IconBrandLinkedin />
          </Link>
          <Link
            target="blank"
            to="/"
            className="rounded-full border border-gray-100 p-3 shadow-sm"
          >
            <IconBrandInstagram />
          </Link>
          <Link
            target="blank"
            to="/"
            className="rounded-full border border-gray-100 p-3 shadow-sm"
          >
            <IconBrandX />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Footer;
