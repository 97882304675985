import React from "react";
import Button from "../../components/Button";
import Titlepage from "../../components/Titlepage";
import { IconMail, IconMapPin, IconPhone } from "@tabler/icons-react";
function ContactUs() {
  return (
    <div data-aos="fade-up">
      <div className="max-w-[1344px] mx-auto">
        <Titlepage title="Get in Touch" />
        <div className="lg:gap-x-[64px] md:gap-x-[32px] gap-y-[48px] grid md:grid-cols-2 grid-cols-1 gap-6 px-4">
          <div className="col-span-1">
            <div>
              <div className="text-gray-800 flex items-center gap-3 mb-3">
                <IconPhone size={20} />
                <a href="tel:+13104000531">+1 (310) 400-0531</a>
              </div>
              <div className="text-gray-800 flex items-center gap-3 mb-3">
                <IconMail size={20} />
                <a href="mailto:hello@millenniuxt.com">hello@millenniuxt.com</a>
              </div>
              <div className="text-gray-800 flex items-center gap-3 mb-3">
                <IconMapPin size={20} />
                <p>8 The Green Ste 300, Dover DE 19901</p>
              </div>
            </div>
          </div>
          <div className="col-span-1 grid gap-y-6">
            <form className="grid gap-y-4">
              <div className="flex gap-4">
                <div className=" w-full">
                  <p className="font-UncutSans text-gray-700 text-sm mb-1 font-medium">
                    Name<sup>*</sup>{" "}
                  </p>
                  <input
                    type="text"
                    placeholder="Enter your name"
                    className="font-UncutSans text-gray-800 rounded-[4px] border border-gray-200 py-5 px-3 w-full"
                  />
                </div>
                <div className=" w-full">
                  <p className="font-UncutSans text-gray-700 text-sm mb-1 font-medium">
                    Email<sup>*</sup>{" "}
                  </p>
                  <input
                    type="text"
                    placeholder="Enter your email"
                    className="font-UncutSans text-gray-800 rounded-[4px] border border-gray-200 py-5 px-3 w-full"
                  />
                </div>
              </div>
              <div className=" w-full">
                <p className="font-UncutSans text-gray-700 text-sm mb-1 font-medium">
                  Select Subject<sup>*</sup>{" "}
                </p>
                <input
                  type="text"
                  placeholder="Select subject for reaching out"
                  className="font-UncutSans text-gray-800 rounded-[4px] border border-gray-200 py-5 px-3 w-full"
                />
              </div>
              <div className=" w-full">
                <p className="font-UncutSans text-gray-700 text-sm mb-1 font-medium">
                  Write Message
                </p>
                <textarea
                  placeholder="Write why you are reaching out"
                  className="font-UncutSans text-gray-800 rounded-[4px] border border-gray-200 py-2 px-3 w-full h-[160px] overflow-hidden resize-none"
                ></textarea>
              </div>
              <Button btnText="Save Message" classnameadd="w-full" />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
