import React from "react";
import Titlepage from "../../components/Titlepage";
import supportiqwhitelogo from "../../images/supportiqwhitelogo.png";

function News() {
  return (
    <div data-aos="fade-up">
      <Titlepage title="Millenniuxt In the News" />
      <div className="px-4">
        <div className="max-w-[1344px] mx-auto">
          <div className="lg:gap-x-[64px] md:gap-x-[32px] gap-y-[48px] grid md:grid-cols-2 grid-cols-1 gap-6 items-center">
            {[1, 2, 3, 4].map((item, index) => (
              <div className="col-span-1" key={index}>
                <div className="overflow-hidden h-[320px] bg-image-h1 rounded-md flex items-center justify-center mb-8">
                  <div className="flex items-center gap-2">
                    <img src={supportiqwhitelogo} alt={supportiqwhitelogo} />
                    <h3 className="text-white text-[34px] font-semibold">
                      SupportIQ
                    </h3>
                  </div>
                </div>
                <h3 className="text-[28px] font-UncutSans mb-4 text-gray-800 leading-7">
                  Millenniuxt Inc. Acquires AI-Powered Omnichannel Customer
                  Engagement Platform SupportIQ.AI
                </h3>
                <p className="text-gray-700">May 21, 2024 | PRNewswire-PRWeb</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default News;
