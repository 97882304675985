import React from "react";
import Threecols from "./Threecols";

function Point() {
  const pointdata = [
    {
      count: "1",
      title: "Innovative Technology",
      content:
        "We leverage the latest advancements in AI and ML to deliver powerful, efficient solutions tailored to your business needs.",
    },
    {
      count: "2",
      title: "Expert Team",
      content:
        "Our team of seasoned professionals brings deep industry expertise and a commitment to excellence, ensuring you receive the best possible service and support.",
    },
    {
      count: "3",
      title: "Proven Results",
      content:
        "Our AI-driven solutions have transformed operations for businesses across various industries, driving growth and enhancing productivity. ",
    },
  ];

  return (
    <div className="md:pt-[120px] pt-[60px] pb-[60px]">
      <div className="gap-6 max-w-[1344px] px-4 mx-auto">
        <div className="max-w-[800px]">
          <h3 className="sm:text-[44px] text-4xl text-gray-800 font-UncutSans mb-2">
            Why Milleniuxt?
          </h3>
          <p className="text-gray-800">
            Discover AI-Driven Solutions That Transform Business Operations and
            Drive Growth
          </p>
        </div>
        <Threecols pointdata={pointdata} />
      </div>
    </div>
  );
}

export default Point;
