import React from "react";
import CenterTitle from "../../components/CenterTitle";
import francis from "../../images/francis.jpg";
import sagar from "../../images/sagar.jpg";
import chen from "../../images/cheng.jpg";
import tushar from "../../images/tushar.jpg";
import { IconBrandLinkedin } from "@tabler/icons-react";
import { Link } from "react-router-dom";

function OurTeam() {
  const pointdata = [
    {
      title: "Francis Romeo",
      position: "CEO & Founder",
      image: francis,
      link: "https://www.linkedin.com/in/francisromeo",
      icon: <IconBrandLinkedin />,
      content:
        "Francis Romeo is a distinguished technology executive with a proven track record in innovation. A seasoned software engineer with deep expertise in cutting-edge fields like AI, blockchain, and fintech, he leverages his technical prowess to drive industry-leading solutions. Francis's visionary leadership has had a profound impact on the technology landscape.",
    },
    {
      title: "Sagar Chopada",
      position: "COO & Co-Founder",
      image: sagar,
      link: "https://www.linkedin.com/in/sagar-chopada",
      icon: <IconBrandLinkedin />,
      content:
        "10+ years of experience streamlining processes and maximizing resource efficiency. I align workflows for peak productivity and achieve organizational goals. Dedicated, detail-oriented, I bring continuous improvement and a strategic approach to ensure optimal outcomes. Let's connect! ",
    },
    {
      title: "MJ Chen",
      position: "EVP & Co-Founder",
      image: chen,
      link: "https://www.linkedin.com/in/miranda-chen-a3b459b8",
      icon: <IconBrandLinkedin />,
      content:
        "J. Chen is a seasoned Executive Vice President of Business Affairs with a proven track record of building strong relationships and driving growth. She leverages her deep understanding of client needs to forge strategic partnerships that deliver exceptional results.",
    },
    {
      title: "Tushar Chopada",
      position: "CTO & Co-Founder",
      image: tushar,
      link: "https://www.linkedin.com/in/tusharchopada?original_referer=https%3A%2F%2Fmillenniuxt.com%2F",
      icon: <IconBrandLinkedin />,
      content:
        "8 years in tech, specializing in cloud services. I wield JavaScript & Python to craft robust solutions. My diverse skillset tackles any challenge. Dedicated to innovation & success. Let's connect - I can power up your next project!",
    },
  ];
  return (
    <div className="py-[60px] max-w-[1344px] px-4 mx-auto">
      <div className="mb-[80px]">
        <CenterTitle
          title="Our Team"
          subtext="Meet the Experts Driving Innovation with AI and Machine Learning Solutions"
        />
      </div>
      <div className="grid lg:grid-cols-4 sm:grid-cols-2 grid-col-1">
        {pointdata?.map((item, index) => {
          return (
            <div key={index} className="bg-white rounded p-3">
              <div className="col-span-1 relative group overflow-hidden rounded-[4px] border border-gray-100">
                <div className="absolute bg-white rounded-[4px] left-4 bottom-0 p-2 w-[80%] opacity-0 group-hover:opacity-100 group-hover:bottom-4 transition-all">
                  <p className="text-xl text-gray-800 font-UncutSans font-semibold">
                    {item?.title}
                  </p>
                  <p className="text-base text-gray-800 font-UncutSans ">
                    {item?.position}
                  </p>
                  <Link to={item?.link} target="blank">
                    {item?.icon}
                  </Link>
                </div>
                <img src={item?.image} alt={item?.title} className="w-full" />
              </div>
              <div className="overflow-hidden col-span-1 hidden">
                <p className="text-gray-700 text-sm mt-2 p-2">{item.content}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default OurTeam;
