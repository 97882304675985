import React from "react";
import error404 from "../images/error404.jpg";

function Error404() {
  return (
    <div>
      <div className="px-4">
        <div className="max-w-[1344px] mx-auto">
          <img src={error404} alt="error404" className="mx-auto" />
        </div>
      </div>
    </div>
  );
}

export default Error404;
